<template>
  <div class="content">
    <h1 class="title title--big title--theme title--indent">Статистика</h1>
    <preloader v-if="!is_loaded" />
    <template v-else>
      <h2 class="title title--indent">Пользователи</h2>
      <div class="stat-list">
        <div class="stat-list__item">
          <div class="stat-list__info">
            <div class="stat-list__count">{{ stat.users.all }}</div>
            <p class="stat-list__desc">Всего</p>
          </div>
        </div>
        <div class="stat-list__item stat-list__item--neutral">
          <div class="stat-list__info">
            <div class="stat-list__count">{{ stat.users.studvesna }}</div>
            <p class="stat-list__desc">Участников программы РСВ</p>
          </div>
        </div>
      </div>

      <h2 class="title title--indent">Зарегистрировано пользователей</h2>
      <div class="stat-list">
        <div class="stat-list__item">
          <div class="stat-list__info">
            <div class="stat-list__count">{{ stat.registers.today }}</div>
            <p class="stat-list__desc">Сегодня</p>
          </div>
        </div>
        <div class="stat-list__item stat-list__item--success">
          <div class="stat-list__info">
            <div class="stat-list__count">{{ stat.registers.yesterday }}</div>
            <p class="stat-list__desc">Вчера</p>
          </div>
        </div>
        <div class="stat-list__item stat-list__item--success">
          <div class="stat-list__info">
            <div class="stat-list__count">{{ stat.registers.week }}</div>
            <p class="stat-list__desc">За 7 дней</p>
          </div>
        </div>
        <div class="stat-list__item stat-list__item--success">
          <div class="stat-list__info">
            <div class="stat-list__count">{{ stat.registers.month }}</div>
            <p class="stat-list__desc">За 30 дней</p>
          </div>
        </div>
      </div>

      <h2 class="title title--indent">Мероприятия</h2>
      <div class="stat-list">
        <div class="stat-list__item">
          <div class="stat-list__info">
            <div class="stat-list__count">{{ stat.events.all }}</div>
            <p class="stat-list__desc">Всего мероприятий</p>
          </div>
        </div>
        <div class="stat-list__item stat-list__item--abort">
          <div class="stat-list__info">
            <div class="stat-list__count">{{ stat.events.active }}</div>
            <p class="stat-list__desc">Активных мероприятий</p>
          </div>
        </div>
        <div class="stat-list__item stat-list__item--archive">
          <div class="stat-list__info">
            <div class="stat-list__count">{{ stat.events.archive }}</div>
            <p class="stat-list__desc">Мероприятий в архиве</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Preloader from '@/components/layout/Preloader'

export default {
  name: 'Stat',
  components: { Preloader },
  data() {
    return {
      is_loaded: false,
      stat: null,
    }
  },
  created() {
    this.$store.dispatch('stat/GET_MAIN_STAT')
      .then(response => {
        this.stat = response.data
        console.log(this.stat)
        this.is_loaded = true
      })
  }
}
</script>

<style lang="sass">
.stat-list
  display: grid
  grid-template-columns: repeat(auto-fill, 292px)
  column-gap: 15px
  row-gap: 20px
  margin-bottom: 20px

.stat-list__item
  min-height: 100px
  padding: 15px
  border-radius: 7px
  background-image: linear-gradient(256.92deg, #7ec7ff 0.85%, #1d87dd 98.53%)
  box-shadow: 0 2px 6px rgba(43, 147, 231, 0.25)

.stat-list__item--abort
  background-image: linear-gradient(256.92deg, #ffbdc8 0.85%, #ea5467 98.53%)

.stat-list__item--success
  background-image: linear-gradient(256.21deg, #bde9c8 0%, #38bf4e 96.91%)

.stat-list__item--neutral
  background-image: linear-gradient(256.21deg, #fadcb6 0%, #ff9416 96.91%)

.stat-list__item--archive
  background-image: linear-gradient(256.21deg, #ddd 0%, #aaa 96.91%)

.stat-list__count
  color: #fff
  font-size: 24px
  font-weight: 700

.stat-list__desc
  margin-bottom: 0
  color: #fff
  font-size: 14px
</style>
